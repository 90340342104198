@use "@angular/material" as mat;

@mixin stream-item-theme($theme) {
  $is-dark-theme: map-get($theme, "is-dark");
  $background: map-get($theme, "background");
  $foreground: map-get($theme, "foreground");

  $background-shadow: if(
    $is-dark-theme,
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.1)
  );

  .stream-item {
    .statistics {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }

    .current {
      color: if(
        $is-dark-theme,
        map-get(mat.define-palette(mat.$red-palette), 300),
        map-get(mat.define-palette(mat.$red-palette), 800)
      );
    }

    .profile,
    .thumbnail {
      background-color: $background-shadow;
    }
  }
}

@mixin stream-item-typography($config) {
  .stream-item {
    .duration {
      font-size: mat.font-size($config, "caption");
      font-weight: mat.font-weight($config, "body-2");
    }

    .title {
      font-size: mat.font-size($config, "body-1");
      font-weight: mat.font-weight($config, "body-1");
    }

    .meta,
    .statistics {
      font-size: mat.font-size($config, "caption");
      font-weight: mat.font-weight($config, "body-1");
    }
  }
}
