@use "@angular/material" as mat;

@mixin update-indicator-theme($theme) {
  $foreground: map-get($theme, "foreground");

  .update-indicator {
    .mat-icon {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }
  }
}
