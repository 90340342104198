@use "@angular/material" as mat;

@mixin sub-menu-theme($theme) {
  $foreground: map-get($theme, "foreground");

  .sub-menu {
    .extra {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }
  }
}

@mixin sub-menu-typography($config) {
  .sub-menu {
    .title {
      font-size: mat.font-size($config, "subheading-2");
      font-weight: mat.font-weight($config, "subheading-2");
    }

    .extra {
      font-size: mat.font-size($config, "caption");
      font-weight: mat.font-weight($config, "caption");
    }
  }
}
