@use "@angular/material" as mat;

@mixin chart-compare-theme($theme) {
  $is-dark-theme: map-get($theme, "is-dark");
  $foreground: map-get($theme, "foreground");

  .chart-compare {
    color: mat.get-color-from-palette($foreground, "secondary-text");

    &.negative {
      color: if(
        $is-dark-theme,
        map-get(mat.define-palette(mat.$red-palette), 300),
        map-get(mat.define-palette(mat.$red-palette), 800)
      );
    }

    &.positive {
      color: if(
        $is-dark-theme,
        map-get(mat.define-palette(mat.$green-palette), 300),
        map-get(mat.define-palette(mat.$green-palette), 800)
      );
    }
  }
}
