@use "@angular/material" as mat;

@mixin channel-table-theme($theme) {
  $is-dark-theme: map-get($theme, "is-dark");
  $foreground: map-get($theme, "foreground");

  $background-shadow: if(
    $is-dark-theme,
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.1)
  );

  .channel-table {
    .negative {
      color: if(
        $is-dark-theme,
        map-get(mat.define-palette(mat.$red-palette), 300),
        map-get(mat.define-palette(mat.$red-palette), 800)
      );
    }

    .positive {
      color: if(
        $is-dark-theme,
        map-get(mat.define-palette(mat.$green-palette), 300),
        map-get(mat.define-palette(mat.$green-palette), 800)
      );
    }

    .profile {
      background-color: $background-shadow;
    }
  }
}
