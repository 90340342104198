@use "@angular/material" as mat;

@mixin not-found-theme($theme) {
  $foreground: map-get($theme, "foreground");

  .not-found {
    .watame,
    .title {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }
  }
}

@mixin not-found-typography($config) {
  .not-found {
    .title {
      font-size: mat.font-size($config, "display-1");
      font-weight: mat.font-weight($config, "display-1");
    }

    .watame {
      font-size: mat.font-size($config, "body-2");
      font-weight: mat.font-weight($config, "body-2");
    }
  }
}
