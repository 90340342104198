@use "@angular/material" as mat;

@mixin stream-stats-chart-theme($theme) {
  $is-dark-theme: map-get($theme, "is-dark");
  $foreground: map-get($theme, "foreground");
  $background: map-get($theme, "background");

  .stream-stats-chart {
    .label {
      fill: #8f8f8f;
    }

    .guideline {
      stroke: if($is-dark-theme, rgb(255, 255, 255, 0.5), rgb(0, 0, 0, 0.5));
    }

    .line {
      stroke: mat.get-color-from-palette($foreground, "divider");
    }

    .stroke {
      stroke: mat.get-color-from-palette($background, "card");
      fill: mat.get-color-from-palette($background, "card");
    }
  }

  .time-unit.selected {
    color: mat.get-color-from-palette($foreground, "text");
  }
}

@mixin stream-stats-chart-typography($config) {
  .stream-stats-chart {
    .label,
    .stroke {
      font-size: mat.font-size($config, "caption");
      font-weight: mat.font-weight($config, "body-2");
    }
  }

  .stream-stats-chart-tooltip {
    font-size: mat.font-size($config, "caption");
    font-weight: mat.font-weight($config, "body-2");
  }
}
