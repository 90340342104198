@use "@angular/material" as mat;

@mixin settings-typography($config) {
  .settings {
    .title {
      font-size: mat.font-size($config, "subheading-2");
      font-weight: mat.font-weight($config, "subheading-2");
    }
  }
}
