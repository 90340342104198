body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  padding: 0;
  margin: 0;
}

.relative {
  position: relative;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.max-w-960 {
  max-width: 960px;
}
