@use "@angular/material" as mat;

@mixin header-theme($theme) {
  $foreground: map-get($theme, "foreground");
  $background: map-get($theme, "background");

  .header {
    background-color: mat.get-color-from-palette($background, "card");
    border-bottom: solid 1px mat.get-color-from-palette($foreground, "divider");
  }
}
