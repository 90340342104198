@use "@angular/material" as mat;
@use "@angular/cdk" as cdk;

// layout
@import "src/app/layout/layout-theme";

// components
@import "src/app/components/date-filter/date-filter-theme";
@import "src/app/components/stream-item/stream-item-theme";
@import "src/app/components/sub-menu/sub-menu-theme";
@import "src/app/components/vtuber-filter/vtuber-filter-theme";

// pages
@import "src/app/pages/not-found/not-found-theme";
@import "src/app/pages/channels-list/channels-list-theme";
@import "src/app/pages/settings/settings-theme";
@import "src/app/pages/streams-detail/streams-detail-theme";
@import "src/app/pages/vtubers-detail/vutbers-detail-theme";

@include mat.ripple();
@include cdk.a11y-visually-hidden();
@include cdk.overlay();
@include cdk.text-field();

// FIXME
mat.$theme-ignore-duplication-warnings: true;

@mixin hls-theme($theme) {
  @include global-theme($theme);
  @include material-theme($theme);
  @include stream-item-theme($theme);
  @include channels-list-theme($theme);
  @include sub-menu-theme($theme);
  @include layout-theme($theme);
  @include not-found-theme($theme);
  @include date-filter-theme($theme);
  @include vtuber-filter-theme($theme);
  @include stream-details-theme($theme);
  @include vtubers-details-theme($theme);
}

@mixin hls-typography($config) {
  @include material-typography($config);
  @include not-found-typography($config);
  @include stream-item-typography($config);
  @include sub-menu-typography($config);
  @include layout-typography($config);
  @include settings-typography($config);
  @include vtuber-filter-typography($config);
  @include stream-details-typography($config);
  @include vtubers-details-typography($config);
}

@include hls-typography($hls-typography-config);

@include hls-theme($hls-light-theme);

.dark {
  @include hls-theme($hls-dark-theme);
}
