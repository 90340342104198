@use "@angular/material" as mat;

@mixin stream-summary-theme($theme) {
  $foreground: map-get($theme, "foreground");
  $is-dark-theme: map-get($theme, "is-dark");

  $background-shadow: if(
    $is-dark-theme,
    rgba(255, 255, 255, 0.1),
    rgba(0, 0, 0, 0.1)
  );

  .stream-summary {
    .title,
    .name {
      color: mat.get-color-from-palette($foreground, "text");
    }

    .profile,
    .cover {
      background-color: $background-shadow;
    }
  }
}

@mixin stream-summary-typography($config) {
  .stream-summary {
    .name {
      font-size: mat.font-size($config, "body-2");
      font-weight: mat.font-weight($config, "body-2");
    }

    .title {
      font-size: mat.font-size($config, "title");
      font-weight: mat.font-weight($config, "title");
    }
  }
}
