@use "@angular/material" as mat;

@mixin live-chat-theme($theme) {
  $is-dark-theme: map-get($theme, "is-dark");
  $foreground: map-get($theme, "foreground");
  $background: map-get($theme, "background");

  .live-chat-chart {
    .label {
      fill: mat.get-color-from-palette($foreground, "hint-text");
    }

    .line {
      stroke: mat.get-color-from-palette($foreground, "divider");
    }

    .stroke {
      stroke: mat.get-color-from-palette($background, "card");
      fill: mat.get-color-from-palette($background, "card");
    }
  }
}

@mixin live-chat-typography($config) {
  .live-chat-chart {
    .label,
    .stroke {
      font-size: mat.font-size($config, "caption");
      font-weight: mat.font-weight($config, "body-2");
    }
  }
}
