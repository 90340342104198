@use "@angular/material" as mat;

@mixin date-filter-theme($theme) {
  $foreground: map-get($theme, "foreground");
  $background: map-get($theme, "background");

  .date-filter-overlay {
    background-color: mat.get-color-from-palette($background, "card");
  }

  .date-filter-arrow {
    border-top-color: mat.get-color-from-palette($foreground, "icon");
  }
}
