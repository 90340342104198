@use "@angular/material" as mat;

@mixin sidenav-theme($theme) {
  $is-dark-theme: map-get($theme, "is-dark");
  $foreground: map-get($theme, "foreground");

  .sidenav {
    .copyright {
      color: mat.get-color-from-palette($foreground, "hint-text");
    }

    .link {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }

    .list > a {
      color: if(
        $is-dark-theme,
        map-get(mat.define-palette(mat.$gray-palette), 500),
        map-get(mat.define-palette(mat.$gray-palette), 600)
      );

      &.active {
        color: if(
          $is-dark-theme,
          map-get(mat.define-palette(mat.$gray-palette), 100),
          map-get(mat.define-palette(mat.$gray-palette), 900)
        );
      }
    }
  }
}

@mixin sidenav-typography($config) {
  .sidenav {
    .info {
      font-size: mat.font-size($config, "button");
      font-weight: mat.font-weight($config, "button");
    }

    .copyright {
      font-size: mat.font-size($config, "caption");
      font-weight: mat.font-weight($config, "caption");
    }
  }
}
