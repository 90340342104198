@use "@angular/material" as mat;

@mixin stream-time-theme($theme) {
  $is-dark-theme: map-get($theme, "is-dark");
  $foreground: map-get($theme, "foreground");

  .stream-time-chart {
    .label {
      fill: mat.get-color-from-palette($foreground, "secondary-text");
    }

    .line {
      stroke: mat.get-color-from-palette($foreground, "divider");
    }
  }
}

@mixin stream-time-typography($config) {
  .stream-time-chart-tooltip {
    font-size: mat.font-size($config, "caption");
    font-weight: mat.font-weight($config, "body-2");
  }
}
