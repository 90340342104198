@use "@angular/material" as mat;

@mixin paid-chat-chart-theme($theme) {
  $is-dark-theme: map-get($theme, "is-dark");
  $foreground: map-get($theme, "foreground");

  .paid-chat-chart {
    .label {
      fill: mat.get-color-from-palette($foreground, "hint-text");
    }

    .line {
      stroke: mat.get-color-from-palette($foreground, "divider");
    }

    .v.label {
      fill: mat.get-color-from-palette($foreground, "secondary-text");
    }

    .caption {
      color: mat.get-color-from-palette($foreground, "secondary-text");
    }
  }
}

@mixin paid-chat-chart-typography($config) {
  .paid-chat-chart {
    .label {
      font-size: mat.font-size($config, "caption");
      font-weight: mat.font-weight($config, "body-2");
    }

    .caption {
      font-size: mat.font-size($config, "body-1");
      font-weight: mat.font-weight($config, "body-1");
    }
  }

  .paid-chat-popper {
    .label {
      font-size: mat.font-size($config, "body-1");
      font-weight: mat.font-weight($config, "body-1");
    }
  }
}
